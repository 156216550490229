import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import Slider from '@mui/material/Slider';

import Link from "@mui/material/Link";



import numeral from "numeral";


import Typography from "@mui/material/Typography";


import "./App.css";

import { styled } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import { Chart } from "react-google-charts";
import { Legend } from "chart.js";



var amortize = require("amortize");

export const CustomCurrencyTextField = styled((props) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
      type: "number",
      
    }}
    {...props}
  />
))(({ theme }) => ({
  paddingBottom: "15px",
  width: "100%",
}));

export const CustomNumberTextField = styled((props) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    InputProps={{
      type: "number",
      defaultValue: 0
      
    }}
    {...props}
  />
))(({ theme }) => ({
  paddingBottom: "15px",
  width: "100%",
}));

export const CustomPctTextField = styled((props) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    InputProps={{
      
            
    }}
    {...props}
  />
))(({ theme }) => ({
  paddingBottom: "15px",
  width: "100%",
}));

function App() {
  const [sPrice, setSalePrice] = useState(500000);
  const [dPmt, setDownPmt] = useState(100000);
  
  const [intRate, setRate] = useState(6.25);
  const [loanTerm, setLoanTerm] = useState(30);
  const [mortgagePmt, setMortgagePmt] = useState(0);
  const [taxes, setTaxesAmt] = useState(10000);
  const [hoa, setHOAPmt] = useState(200);
  const [inspection, setInspectionPmt] = useState(800);
  const [closing, setClosingCost] = useState(10000);
  const [sewer, setSewerPmt] = useState(200);
  const [insurance, setInsurancePmt] = useState(1000);
  const [upkeep, setUpkeepAmt] = useState(50);
  const [totalMonthlyExpense, setTotalMonthlyExpense] = useState(0);
  const [additionalMonthlyExpense, setAdditionalMonthlyExpense] = useState(0);
  const [upfrontCosts, setUpfrontCosts] = useState(0);
  const [rentingCosts, setRentingCosts] = useState(1500);
  const [rentalIncome, setRentalIncome] = useState(2200);
  const [expensesData, setExpensesData] = useState([]);

  const currencyFormatter = (item) => numeral(item).format("$0,0");
  const currencyFormatterFull = (item) => numeral(item).format("$0,0.00");

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "scroll",
    p: 4,
  };
  const worstCaseInflationRate = -.2
  const bestCaseInflationRate = .2

  const inflationRates = [
    {
      value: 0.0,
      label: "0.0%",
    },
    {
      value: 0.01,
      label: "1.0%",
    },
    {
      value: 0.02,
      label: "2.0%",
    },
    {
      value: 0.03,
      label: "3.0%",
    },
    {
      value: 0.04,
      label: "4.0%",
    },
    {
      value: 0.05,
      label: "5.0%",
    },
    {
      value: 0.06,
      label: "6.0%",
    },
  ];
  const [inflationRate, setInflationRate] = useState(inflationRates[3].value);
  const [saleCommissionRate, setCommissionRate] = useState(
    inflationRates[5].value
  );
  const [appreciationRate, setAppreciationRate] = useState(
    inflationRates[2].value
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function createExpenseData(
    totalMonthlyExpense,
    rate,
    loanTerm,
    loanAmount,
    upfrontCosts,
    additionalMonthlyExpense,
    mortgagePmt
  ) {
    const startYear = new Date().getFullYear();
    var expensesData = [];
    var buyRentData = [];
    var yearTotalCosts = upfrontCosts;
    var balance = loanAmount;
    var netProfit = 0;
    var a;
    var salePrice = sPrice;
    var commissionAmount = 0;
    var interestPaid = 0;
    var rentingCostsYearly = 0;

    setExpensesData([]);
    var expense = 0;
    var totalIncome = 0;
    var costOfBuying = 0;
    var rentPerMonth = 0;
    var highGuardrail = .2;
    var lowGuardrail = .2;
    var changeFactor = .8;

    for (var i = 0; i <= loanTerm; i++) {
      var year = startYear + i;
      expense =  mortgagePmt + (additionalMonthlyExpense * Math.pow(1+inflationRate, i));            
      
      a = amortize({
        amount: loanAmount,
        rate: rate,
        totalTerm: loanTerm * 12,
        amortizeTerm: i * 12,
      });
      
      var totalPrincipalPaid = a.principal;
      var propertyWorth = sPrice * Math.pow(1 + appreciationRate, i);
      var propertyWorthHigh = propertyWorth * (1 + (highGuardrail * Math.pow(changeFactor,i)))
      var propertyWorthLow =  propertyWorth * (1 - (lowGuardrail * Math.pow(changeFactor,i)))
      balance = a.balance;
      interestPaid = a.interest;
      commissionAmount = propertyWorth * saleCommissionRate;
      netProfit = propertyWorth - commissionAmount - balance;
      costOfBuying = yearTotalCosts - propertyWorth + commissionAmount + a.balance;
      
      var profitPerMonth = rentPerMonth - expense - 0;
      
      var netIncome = propertyWorth - commissionAmount - balance - upfrontCosts;
      var netIncomeHigh = propertyWorthHigh - commissionAmount - balance - upfrontCosts;
      var netIncomeLow = propertyWorthLow - commissionAmount - balance - upfrontCosts;
      expensesData.push({
        year,
        expense,
        yearTotalCosts,
        balance,
        netProfit,
        interestPaid,
        totalIncome,
        rentPerMonth,
        profitPerMonth,
        totalPrincipalPaid,        
        rentingCostsYearly,
        costOfBuying,
        netIncome,
        netIncomeHigh,
        netIncomeLow

    });
   
      
      
      
      //marks.push(i)
    }
    setExpensesData(expensesData);
    buyRentData.push(expensesData[0]);
    //const output = expensesData[0].map((col, i) => expensesData.map(row => row[i]));
    //setExpensesData(output);
    //const arr = new Array(["Year","Expense"]).concat (expensesData.map((dataPoint) => ([dataPoint.year, dataPoint.expense])));
    
    //console.log(arr);
  }

  function pmt(
    rate_per_period,
    number_of_years,
    present_value,
    future_value,
    type
  ) {
    future_value = typeof future_value !== "undefined" ? future_value : 0;
    type = typeof type !== "undefined" ? type : 0;
    rate_per_period = rate_per_period / 1200;
    let number_of_payments = number_of_years * 12;

    if (rate_per_period !== 0.0) {
      // Interest rate exists
      var q = Math.pow(1 + rate_per_period, number_of_payments);
      return (
        -(rate_per_period * (future_value + q * present_value)) /
        ((-1 + q) * (1 + rate_per_period * type))
      );
    } else if (number_of_payments !== 0.0) {
      // No interest rate, but number of payments exists
      return -(future_value + present_value) / number_of_payments;
    }

    return 0;
  }



  function calculateAll() {
    
    var loanAmount = sPrice - dPmt;
    
    var mortgagePmt = pmt(intRate, loanTerm, -1 * loanAmount, 0);
    var totalMonthlyExpense =
      mortgagePmt + taxes / 12 + hoa + insurance / 12 + sewer / 12 + upkeep;
    var additionalMonthlyExpense = totalMonthlyExpense - mortgagePmt;
    var upfrontCosts = dPmt + inspection + closing;
    setUpfrontCosts(upfrontCosts);
    
    setMortgagePmt(mortgagePmt);
    setTotalMonthlyExpense(totalMonthlyExpense);
    setAdditionalMonthlyExpense(additionalMonthlyExpense);
    createExpenseData(
      totalMonthlyExpense,
      intRate,
      loanTerm,
      loanAmount,
      upfrontCosts,
      additionalMonthlyExpense,
      mortgagePmt
    );
    handleOpen();
  }

  return (
    <div className="App">
      <header className="App-header"></header>
      <body className="App-body">
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography
              variant="h1"
              align="left"
              style={{
                fontFamily: "Plus Jakarta Sans, Arial, sans-serif",
                fontWeight: 800,
                color: "#3399ff",
              }}
            >
              Let's price your dream house.
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Paper
              style={{ height: "600px", overflow: "scroll" }}
              elevation={7}
              variant="elevation"
            >
              <div id="firstpage" style={{ padding: "20px" }}>
                <Typography
                  variant="h6"
                  align="left"
                  style={{
                    fontFamily: "Plus Jakarta Sans, Arial, sans-serif",
                    fontWeight: 800,
                    color: "#3399ff",
                  }}
                >
                  Basics 
                </Typography>
                <br></br>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <CustomCurrencyTextField
                      id="salePrice"
                      label="Purchase Price"
                      value={sPrice}
                      onChange={(event, value) =>
                        setSalePrice(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="downPmt"
                      label="Down Payment"
                      value={dPmt}
                      onChange={(event, value) =>
                        setDownPmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomNumberTextField
                      id="loanTerm"
                      type="number"
                      label="Loan Term (Years)"
                      value={loanTerm}
                      onChange={(event, value) =>
                        setLoanTerm(parseFloat(event.target.value))
                      }
                    />
                    <CustomNumberTextField
                      id="mortgageRate"
                      type="number"
                      label="Mortgage Rate (APR %)"
                      value={intRate}
                      onChange={(event, value) =>
                        setRate(parseFloat(event.target.value))
                      }
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        
                      }}
                    />
                    
                <Typography variant="caption">
                  <Link
                    href="https://www.wellsfargo.com/mortgage/rates/"
                    target="popup"
                    onclick="window.open('https://www.wellsfargo.com/mortgage/rates/','popup','width=200,height=200'); return false;"
                  >
                    VIEW CURRENT LOAN RATES
                  </Link>
                </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomCurrencyTextField
                      id="taxes"
                      value={taxes}
                      label="Property Taxes (Yearly)"
                      onChange={(event, value) =>
                        setTaxesAmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="hoa"
                      value={hoa}
                      label="HOA Fee (Monthly)"
                      onChange={(event, value) =>
                        setHOAPmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="inspection"
                      value={inspection}
                      label="Inspection Costs"
                      onChange={(event, value) =>
                        setInspectionPmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="closing"
                      value={closing}
                      label="One Time Closing Costs"
                      onChange={(event, value) =>
                        setClosingCost(parseFloat(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomCurrencyTextField
                      id="insurance"
                      value={insurance}
                      label="Insurance (Yearly)"
                      onChange={(event, value) =>
                        setInsurancePmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="sewer"
                      value={sewer}
                      label="Sewer and Trash (Yearly)"
                      onChange={(event, value) =>
                        setSewerPmt(parseFloat(event.target.value))
                      }
                    />
                    <CustomCurrencyTextField
                      id="upkeep"
                      value={upkeep}
                      label="Repairs & Maintenance (Monthly)"
                      onChange={(event, value) =>
                        setUpkeepAmt(parseFloat(event.target.value))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Button
                      style={{ width: "50%" }}
                      variant="contained"
                      onClick={() => {
                        calculateAll();
                      }}
                    >
                      Calculate
                    </Button>
                    <br></br><br></br>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      align="left"
                      style={{
                        fontFamily: "Plus Jakarta Sans, Arial, sans-serif",
                        fontWeight: 800,
                        color: "#3399ff",
                      }}
                    >
                      Advanced Options
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <CustomPctTextField
                      id="costinflation"
                      label="Expense inflation rate (Annual)"
                      select
                      defaultValue={inflationRate}
                      onChange={(e) => setInflationRate(e.target.value)}
                    >
                      {inflationRates.map((option) => (
                        <MenuItem
                          id="hOne"
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomPctTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomPctTextField
                      id="saleCommission"
                      select
                      label="Sale Commission %"
                      defaultValue={saleCommissionRate}
                      onChange={(e) => setCommissionRate(e.target.value)}
                    >
                      {inflationRates.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomPctTextField>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomPctTextField
                      id="appreciation"
                      select
                      label="Value Appreciaton (APR)"
                      defaultValue={appreciationRate}
                      onChange={(e) => setAppreciationRate(e.target.value)}
                    >
                      {inflationRates.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </CustomPctTextField>
                  </Grid>
                  
                </Grid>
              </div>
            </Paper>
          </Grid>
          
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              
            >
              <Box sx={boxStyle}>
                <Grid container spacing={2} >
                  
                  
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Typography
                      variant="h5"
                      color="primary"
                      className="App-text"
                      
                    >
                      {currencyFormatterFull(totalMonthlyExpense)}{" "}/ month
                       
                    </Typography>
                    <Typography
                      
                      color="primary"
                      className="App-text"
                    >
                      
                      Mortgage: {currencyFormatterFull(mortgagePmt)}{" "} <br></br>
                      Everything Else:{" "}
                      {currencyFormatterFull(additionalMonthlyExpense)}
                    </Typography>
                    </Grid>
                    
                    <Grid item xs={6} style={{ textAlign: "center" }}>
                    <Typography variant="h5" color="primary">                      
                      {currencyFormatterFull(upfrontCosts)}{" "}
                      </Typography>
                      <Typography variant="h9" color="primary">
                        due at closing
                      
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>                        
                  <Chart
                    chartType="LineChart"
                    
                    data = {
                      new Array(["Year","Expense"]).concat (expensesData.map((dataPoint) => ([dataPoint.year, dataPoint.expense])))
                    }
                    options = {{
                        title: "Monthly Expense Over Time",
                        height: "400",
                        vAxis: {
                          format: 'currency'
                        },
                        hAxis: {
                          format: '####',
                          title:"Year"
                        },
                        chartArea: { left: "15%", right:"15%",  width: "60%"}
                    }}
                     
                    
                  />
                  </Grid>
                  
                 
                  <Grid item xs={12}>
                  
                  <Chart
                    chartType="ScatterChart"
                    
                    data = {
                      new Array([{ type: "number", label: "x" },
                      { type: "number", label: "Profit/Loss" },
                      { id: "i0", type: "number", role: "interval"},
                      { id: "i0", type: "number", role: "interval" }]).concat(new Array(["Year", "Net Income", "Best Case", "Worst Case"]).concat (expensesData.map((dataPoint) => ([dataPoint.year, dataPoint.netIncome, dataPoint.netIncomeHigh, dataPoint.netIncomeLow]))))
                    }
                    
                    options = {{
                      height: "400",
                      legend: 'none',
                      vAxis: {
                        format: 'currency',
                      },
                      hAxis: {
                        format: '####',
                        title:"Year",
                        viewWindow: {
                          min: new Date().getFullYear()-1,
                          max: new Date().getFullYear()+10
                        },
                        
                      },
                      
                      title: "Profit/Loss with Variance with "+appreciationRate*100+"% Annual Inflation Rate",
                      
                      curveType: 'function',
                      style: "boxes",
                      lineWidth: 5,
                      interval: {
                        'i0': { style: "boxes", 'boxWidth': .5, curveType: "function", fillOpacity: .2, color: "blue" , legend: 'true' },
                        
                        
                      },
                      
                     
                      
                    }}
                     
                    
                  />
                  </Grid>

                  
                  <Grid item xs={12}>
                  <Chart
                    chartType="ComboChart"
                    
                    data = {
                      new Array(["Year", "Balance","Interest Paid", "Principal Accrued"]).concat (expensesData.map((dataPoint) => ([dataPoint.year, dataPoint.balance, dataPoint.interestPaid, dataPoint.totalPrincipalPaid])))
                    }
                    options = {{
                      height: "400",
                      vAxis: {
                        format: 'currency',
                      },
                      hAxis: {
                        format: '####',
                        title:"Year",
                        
                        
                      },
                      series: {0: {type: 'line'}, 1: {type: 'area'}, 2: {type: 'area'}  },
                      title: "Amortization Over Time",
                      chartArea: { left: "15%", right:"15%", width: "60%"}
                      
                    }}
                     
                    
                  />
                  </Grid>

               
                  
                  
                 

                 

                 

                 

                  
                 
                </Grid>
              </Box>
            </Modal>
          
        </Grid>
      </body>
    </div>
  );
}

export default App;
